<template>
  <main>
    <b-overlay :show="loading">
      <validation-observer ref="form">
        <b-card title="Daftar Ajuan Mutasi Barang">
          <b-row>
            <b-col md="2" sm="4" class="">
              <b-modal
                v-if="allowCreate()"
                v-model="showModalTambah"
                id="modal-tambah"
                cancel-variant="secondary"
                ok-title="Tempatkan"
                cancel-title="Batal"
                centered
                size="lg"
                v-b-modal.modal-lg
                title="Form Tempatkan Barang"
              >
                <b-form>
                  <b-form-group>
                    <label for="namaBarang">Nama Barang</label>
                    <b-form-input
                      v-model="namaBarang"
                      id="namaBarang"
                      placeholder="Nama Barang"
                      disabled
                    />
                    <!-- hidden -->
                  </b-form-group>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <label for="jumlah">Jumlah</label>
                        <b-form-input
                          v-model="jumlah"
                          type="number"
                          id="jumlah"
                          placeholder="Jumlah"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <label for="satuan">Satuan</label>
                        <b-form-input
                          v-model="satuan"
                          id="satuan"
                          placeholder="satuan"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-table
                    responsive
                    striped
                    bordered
                    :fields="fieldsPenyimpanan"
                    :items="penyimpanans"
                  >
                    <template #cell(action)="{ item }">
                      <b-form-radio
                        v-model="selectedBarang"
                        :value="item"
                        :name="`barang`"
                      />
                    </template>
                    
                  </b-table>
                </b-form>

                <template #modal-footer>
                  <section
                    class="d-flex justify-content-end align-items-center"
                  >
                    <b-button
                      size="sm"
                      class="mr-1"
                      variant="primary"
                      @click="submit"
                    >
                      Tempatkan
                    </b-button>
                    <b-button size="sm" variant="danger" @click="resetForm">
                      Batal
                    </b-button>
                  </section>
                </template>
              </b-modal>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"
                  >Per page</label
                >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-25"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                label="Filter"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-table
                small
                bordered
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(no)="row">
                  <strong class="text-center">
                    {{ row.index + 1 }}
                  </strong>
                </template>
                <template #cell(appr)="data">
                  <b-badge :variant="iappr[1][data.value]">
                    {{ iappr[0][data.value] }}
                  </b-badge>
                </template>
                <template #cell(actions)="row">
                  <b-button
                    v-if="allowCreate()"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="add(row.item)"
                    size="sm"
                  >
                    <feather-icon icon="PlusIcon" class="mr-50" />
                    Tempatkan Barang
                  </b-button>
                </template>
              </b-table>
            </b-col>
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
        <!-- two card -->
        <b-row>
          <!-- Barang yang sudah disimpan -->
          <b-col cols="12">
            <b-card title="Daftar Terima Mutasi Barang">
              <b-row>
                
                <b-col md="4" sm="12">
                  <b-button variant="outline-warning" @click="modal()">
                      
                  <feather-icon icon="PrinterIcon"></feather-icon> Print / Cetak
                    </b-button>
                </b-col>
              </b-row>
              <b-modal
                no-close-on-backdrop
                no-close-on-esc
                v-model="modalCetak"
                id="modal-tambah"
                cancel-variant="secondary"
                ok-title="Tambah"
                cancel-title="Batal"
                centered
                title="Pilih Tanggal yang akan di print"
                v-b-modal.modal-no-backdrop
              >
                <b-form>
                  Dari :
                  <b-form-group>
                    <b-form-datepicker
                      id="example-datepicker"
                      v-model="tgl"
                      class="mb-1"
                    />
                  </b-form-group>
                  Ke :
                  <b-form-group>
                    <b-form-datepicker
                      id="example-datepicker"
                      v-model="tglend"
                      class="mb-1"
                    />
                  </b-form-group>
                  Pilih Gudang Pengirim :
                  <b-form-group>
                    <v-select :options="dataGudang" v-model="gudangSelect" label="text" />
                  </b-form-group>
                </b-form>

                <template #modal-footer>
                  <section class="d-flex justify-content-end align-items-center">
                    <b-button size="sm" class="mr-1" variant="primary" @click="unduhbop">
                      Print / Cetak
                    </b-button>
                  </section>
                </template>
              </b-modal>
              <!-- <b-row>
                
                <b-col md="4" sm="12">
                  <b-button variant="outline-warning" @click="modal()">
                      
                  <feather-icon icon="PrinterIcon"></feather-icon> Print / Cetak
                    </b-button>
                </b-col>
              </b-row>
              <b-modal
                v-model="modalCetak"
                id="modal-tambah"
                cancel-variant="secondary"
                ok-title="Tambah"
                cancel-title="Batal"
                centered
                title="Pilih Tanggal yang akan di print"
              >
                <b-form>
                  Dari :
                  <b-form-group>
                    <b-form-datepicker
                      id="example-datepicker"
                      v-model="tgl"
                      class="mb-1"
                    />
                  </b-form-group>
                  Ke :
                  <b-form-group>
                    <b-form-datepicker
                      id="example-datepicker"
                      v-model="tglend"
                      class="mb-1"
                    />
                  </b-form-group>
                </b-form>

                <template #modal-footer>
                  <section class="d-flex justify-content-end align-items-center">
                    <b-button size="sm" class="mr-1" variant="primary" @click="unduhbop">
                      Print / Cetak
                    </b-button>
                  </section>
                </template>
              </b-modal> -->
              <b-row>
                <b-col md="2" sm="4" class="my-1">
                  <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50"
                      >Per page</label
                    >
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPage2"
                      size="sm"
                      :options="pageOptions2"
                      class="w-50"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4" sm="8" class="my-1">
                  <b-form-group
                    label="Sort"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="sortBySelect"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-select
                        id="sortBySelect"
                        v-model="sortBy2"
                        :options="sortOptions2"
                        class="w-75"
                      >
                        <template v-slot:first>
                          <option value="">-- none --</option>
                        </template>
                      </b-form-select>
                      <b-form-select
                        v-model="sortDesc2"
                        size="sm"
                        :disabled="!sortBy"
                        class="w-25"
                      >
                        <option :value="false">Asc</option>
                        <option :value="true">Desc</option>
                      </b-form-select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="my-1">
                  <b-form-group
                    label="Filter"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    label-for="filterInput"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filterInput2"
                        v-model="filter2"
                        type="search"
                        placeholder="Type to Search"
                      />
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                          Clear
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-table
                    small
                    bordered
                    striped
                    hover
                    responsive
                    :per-page="perPage2"
                    :current-page="currentPage2"
                    :items="items2"
                    :fields="fields2"
                    :sort-by.sync="sortBy2"
                    :sort-desc.sync="sortDesc2"
                    :sort-direction="sortDirection2"
                    :filter="filter2"
                    :filter-included-fields="filterOn2"
                    @filtered="onFiltered2"
                  >
                    <template #cell(no)="row">
                      <strong class="text-center">
                        {{ row.index + 1 }}
                      </strong>
                    </template>
                    <template #cell(appr)="data">
                      <b-badge :variant="iappr[1][data.value]">
                        {{ iappr[0][data.value] }}
                      </b-badge>
                    </template>
                    <template #cell(nama_gudang)="data">
                      {{ getPenyimpanandata(data.item) }}
                    </template>

                    <!-- <template #cell(actions)="row"> -->
                    <!-- <b-button
                  v-b-tooltip.hover.right="'Ubah'"
                  size="sm"
                  @click="edit(row.item)"
                  class="mr-1"
                  variant="outline-info"
                >
                  <feather-icon icon="EditIcon" />Ubah
                </b-button> -->
                    <!-- <b-button
                        v-b-tooltip.hover.right="'Hapus'"
                        v-if="allowDelete()"
                        size="sm"
                        @click="remove(row.item)"
                        class="mr-1"
                        variant="outline-danger"
                      >
                        <feather-icon icon="TrashIcon" />Hapus
                      </b-button> -->
                    <!-- </template> -->
                  </b-table>
                </b-col>
                <b-col cols="12">
                  <b-pagination
                    v-model="currentPage2"
                    :total-rows="totalRows2"
                    :per-page="perPage2"
                    align="center"
                    size="sm"
                    class="my-0"
                  />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </main>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BTable,
  VBTooltip,
  BAvatar,
  BBadge,
  BModal,
  VBModal,
  BFormRadio,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  components: {
    vSelect,
    flatPickr,
    BFormRadio,
    BCardActions,
    VBTooltip,
    ToastificationContent,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      selectedBarang: null,
      showModalTambah: false,
      id: null,
      namaBarang: null,
      satuan: null,
      optkonversi: [],
      nilai: 0,
      hasil: 0,
      konversi: 0,
      jumlah: 0,
      penyimpanan_id: "",
      appr: 1,
      keterangan: "",
      tanggal: this.getCurrentDate(),
      barang: [],
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      id_barang: null,

      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        // {
        //   key: "id",
        //   label: "id",
        // },
        { key: "dari_gudang.nama_gudang", label: "gudang", sortable: true },
        { key: "barang.nama", label: "barang", sortable: true },
        { key: "jumlah", label: "jumlah", sortable: true },
        { key: "barang.satuan.satuan", label: "satuan", sortable: true },
        { key: "appr", label: "status", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      iappr: [
        {
          0: "Belum",
          1: "Sudah",
          // 2: "Giro",
          // 3: "Nota Putih",
        },
        {
          0: "light-danger",
          1: "light-success",
          // 2: "light-warning",
          // 4: "light-primary",
        },
      ],
      items: [],
      perPage2: 5,
      pageOptions2: [3, 5, 10],
      totalRows2: 1,
      currentPage2: 1,
      sortBy2: "",
      sortDesc2: false,
      sortDirection2: "asc",
      filter2: null,
      filterOn2: [],
      fieldsPenyimpanan: [
        { key: "action", label: "#" },
        { key: "id", label: "id" },
        { key: "barang.nama", label: "Barang" },
        { key: "blok.blok", label: "blok" },
        { key: "palet.palet", label: "palet" },
        { key: "rak.rak", label: "rak" },
        { key: "laci.laci", label: "laci" },
        { key: "stok", label: "stok" },
        { key: "barang.satuan.satuan", label: "Satuan" },
      ],
      fields2: [
        {
          key: "no",
          label: "No",
        },
        // { key: "tanggal", label: "Tgl", sortable: true },
        { key: "barang.nama", label: "Barang", sortable: true },
        {
          key: "penyimpanan_tujuan",
          label: "Penyimpanan",
          sortable: true,
        },
        // { key: "blok", label: "Blok", sortable: true },
        // { key: "palet", label: "palet", sortable: true },
        // { key: "rak", label: "rak", sortable: true },
        // { key: "laci", label: "laci", sortable: true },
        { key: "jumlah", label: "jumlah", sortable: true },
        {
          key: "barang.satuan.satuan",
          label: "Satuan",
          sortable: true,
        },
        { key: "appr", label: "status", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items2: [],
      dataGudang: [],
      gudangSelect: null,
      tgl: this.getCurrentDate(),
      tglend: this.getCurrentDate(),
      modalCetak: false,
      id_satuan: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.fields2
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items

    this.getData();
    this.getData2();
    this.getDataGudang();
  },
  watch: {
    id_barang(barang_id) {
      this.getKonversi(barang_id);
    },
    jumlah(value2) {
      let hasil = value2 * this.id_satuan;
      this.nilai = hasil;
    },
    id_satuan(value) {
      let hasil = value * this.jumlah;
      this.nilai = hasil;
    },
  },
  methods: {
    async getKonversi(barang_id) {
      const optkonversi = await this.$store.dispatch("konversi/getData", {
        id_barang: barang_id,
      });
      this.optkonversi = [];
      optkonversi.forEach((konv) => {
        this.optkonversi.push({
          value: konv.nilai,
          text: konv.satuan.satuan,
        });
      });
    },
    modal() {
      this.modalCetak = true;
    },
    async unduhbop() {
      try {

        this.loading = true;
        const response = await this.$store.dispatch("mutasi/printAjuan", {
          id_gudang: this.gudangSelect.value,
          id_gudang_tujuan: this.myGudang.id,
          tgl_awal: this.tgl,
          tgl_akhir: this.tglend,
        })
        this.loading = false;
        window.open(URL.createObjectURL(response));
        this.displaySuccess({
                  message: "Data tersedia",
                });
      }
      catch(e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    hem() {
      this.id = null;
      this.activeAction = "tambah";
      this.showModalTambah = true;
      this.showModalEdit = false;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Disposal hilang ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("mutasi/save", [item])
            .then(() => {
              this.displaySuccess({
                message: "Data Barang hilang sudah terhapus",
              });
              this.resetForm();
              this.getData2();
              this.getData();
            })
            .catch((e) => {
              // this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.jumlah = null;
      this.keterangan = null;
      this.showModalTambah = false;
      this.showModalEdit = false;
    },
    submit() {
      let payload = {
        id: this.id,
        // id_gudang : this.myGudang.id,
        jumlah: this.jumlah,
        appr: this.appr,
        penyimpanan_tujuan: this.selectedBarang.id,
      };

      if (this.id) {
        payload.id = this.id;
        // payload.id_gudang = this.myGudang.id;
        payload.jumlah = this.jumlah;
        payload.appr = this.appr;
        payload.penyimpanan_tujuan = this.selectedBarang.id;
      }

      this.$store
        .dispatch("mutasi/save", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.jumlah = null;
          this.showModalTambah = false;
          this.showModalEdit = false;
          this.getData2();
          this.getData();
          this.displaySuccess({
            message: "Data Barang hilang sudah tersimpan",
          });
          // this.pesanBerhasilSimpan();
        })
        .catch((e) => console.error(e));
    },
    async add(item) {
      await this.getPenyimpananBarang(item.barang.id);
      let { id, barang, jumlah } = item;
      this.id = id;
      this.id_barang = barang.id;
      this.namaBarang = barang.nama;
      this.jumlah = jumlah;
      this.satuan = barang.satuan.satuan;

      if(this.penyimpanans.length < 1) {
        // display confirm
        this.$swal({
          title: `Tempat penyimpanan belum ada`,
          text: `Silahkan tambahkan penyimpanan barang ${this.namaBarang}`,
          icon: "warning"
        })

        return false
      }
      else {
        this.showModalTambah = true;
      }
    },
    async getDataGudang() {
      const gudangs = await this.$store.dispatch("gudang/getData", { jenis: 1 });
      gudangs.map((gudang) => {
        if (gudang.id != this.myGudang.id) {
          this.dataGudang.push({
            value: gudang.id,
            text: gudang.nama_gudang,
          });
        }
      });
    },
    async getPenyimpananBarang(barang_id) {
      let params = {
        barang_id,
      };
      if (this.isAdminGudang) {
        params.gudang_id = this.myGudang.id;
      }

      this.penyimpanans = await this.$store.dispatch("penyimpanan/getData", params)
      this.totalRows = this.penyimpanans.length
    },
    pesanBerhasilSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menyimpan data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanBerhasilHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menghapus data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menghapus data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    getData() {
      let params = {};
      if (this.isAdminGudang) {
        params.tujuan = this.myGudang.id;
        params.appr = 0;
        params.order = "desc";
      }
      this.$store
        .dispatch("mutasi/getData", params)
        .then(() => {
          this.items = this.$store.state.mutasi.datas;
          this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
    getData2() {
      let params = {};
      if (this.isAdminGudang) {
        params.tujuan = this.myGudang.id;
        params.appr = 1;
        params.order = "desc";
      }
      this.$store
        .dispatch("mutasi/getData", params)
        .then(() => {
          this.items2 = this.$store.state.mutasi.datas;
          this.totalRows2 = this.items2.length;
        })
        .catch((e) => console.error(e));
    },
    getPenyimpanandata(data) {
      let find = this.items2.penyimpanan_tujuan.find((item) => item.penyimpanan.id == data.items2.penyimpanan_tujuan);
      return find ? find.gudang.nama_gudang : "-";
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFiltered2(filteredItems2) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems2.length;
      this.currentPage2 = 1;
    },
  },
};
</script>
